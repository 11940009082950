<template>
  <el-container id="index" style="height: 100%">
    <el-dialog
      title="修改密码"
      width="350px"
      :visible.sync="changePasswordDialogVisible"
      @close="changePasswordDialogClose"
    >
      <el-form
        ref="changePasswordFormRef"
        :model="changePasswordForm"
        :rules="changePasswordFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-form-item label="原密码" prop="oldPassword">
          <el-input
            v-model="changePasswordForm.oldPassword"
            placeholder="请输入原密码"
            show-password
            clearable
          />
        </el-form-item>
        <el-form-item label="确认原密码" prop="confirmPassword">
          <el-input
            v-model="changePasswordForm.confirmPassword"
            placeholder="请输入确认原密码"
            show-password
            clearable
          />
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            v-model="changePasswordForm.newPassword"
            placeholder="请输入新密码"
            show-password
            clearable
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="changePasswordDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="changePasswordFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-aside width="auto">
      <el-menu
        style="height: 100%"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
        unique-opened
        router
        :collapse="isCollapse"
        :default-active="$route.path"
      >
        <el-scrollbar style="height: 100%">
          <nav-menu :menu-tree="menuTree" />
        </el-scrollbar>
      </el-menu>
    </el-aside>
    <el-container>
      <el-header style="padding: 0px" height="86px">
        <div style="height: 50px">
          <div id="collapse_btn" class="icon_btn" @click="collapseBtnClick">
            <i :class="collapseBtnIcon" />
          </div>
          <el-dropdown trigger="click" @command="dropdownCommand">
            <div>
              <el-avatar icon="el-icon-user-solid" :size="36" />
              <i class="el-icon-arrow-down" />
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="changePassword">
                修改密码
              </el-dropdown-item>
              <el-dropdown-item divided command="logout">
                退出登录
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div id="username">
            {{ $store.state.user.realName }}
          </div>
          <el-tooltip :content="fullscreenTip" placement="bottom">
            <div id="fullscreen_btn" class="icon_btn" @click="fullscreenBtnClick">
              <i :class="fullscreenBtnIcon" />
            </div>
          </el-tooltip>
        </div>
        <div id="tag_box">
          <div id="tag" :style="style" @mousewheel="handerMouserwheel">
            <el-tag
              v-for="tag in $store.state.tagList"
              :key="tag.path"
              type="success"
              size="medium"
              :closable="tag.closable"
              :effect="tag.effect"
              @click="tagClick(tag)"
              @close="tagClose(tag)"
            >
              {{ tag.name }}
            </el-tag>
          </div>
        </div>
      </el-header>
      <div style="height: calc(100% - 86px - 20px - 20px)">
        <transition name="el-zoom-in-top" mode="out-in">
          <router-view :key="key" style="height: 100%; padding: 20px" />
        </transition>
      </div>
    </el-container>
  </el-container>
</template>

<script>
import NavMenu from '@/components/NavMenu'
import { changePassword, rolePermissionMenuTree } from '@/api/system/user'

export default {
  components: {
    'nav-menu': NavMenu
  },
  data () {
    var validateOldPassword = (rule, value, callback) => {
      if (value === this.changePasswordForm.confirmPassword) {
        this.$refs.changePasswordFormRef.clearValidate('confirmPassword')
      }
      callback()
    }
    var validateConfirmPassword = (rule, value, callback) => {
      if (value != this.changePasswordForm.oldPassword) {
        callback(new Error('确认原密码错误'))
      } else {
        callback()
      }
    }
    return {
      menuTree: [],
      collapseBtnIcon: 'el-icon-s-fold',
      isCollapse: false,
      fullscreenBtnIcon: 'el-icon-full-screen',
      isFullscreen: false,
      fullscreenTip: '全屏',
      style: {
        left: '0px'
      },
      changePasswordDialogVisible: false,
      changePasswordForm: {
        oldPassword: '',
        confirmPassword: '',
        newPassword: ''
      },
      changePasswordFormRules: {
        oldPassword: [
          { required: true, message: '原密码不能为空', trigger: ['blur', 'change']},
          { validator: validateOldPassword, trigger: ['blur', 'change']}
        ],
        confirmPassword: [
          { required: true, message: '确认原密码不能为空', trigger: ['blur', 'change']},
          { validator: validateConfirmPassword, trigger: ['blur', 'change']}
        ],
        newPassword: [{ required: true, message: '新密码不能为空', trigger: ['blur', 'change']}]
      }
    }
  },
  computed: {
    key () {
      return this.$route.path
    }
  },
  created () {
    rolePermissionMenuTree().then((res) => {
      this.menuTree = res.menuTree
      this.$store.commit('user', res)
    })
  },
  methods: {
    collapseBtnClick () {
      this.isCollapse = !this.isCollapse
      this.collapseBtnIcon = this.isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'
    },
    fullscreenBtnClick () {
      this.isFullscreen = !this.isFullscreen
      if (this.isFullscreen) {
        this.fullscreenBtnIcon = 'el-icon-switch-button'
        this.fullscreenTip = '退出'
        document.documentElement.webkitRequestFullScreen()
      } else {
        this.fullscreenBtnIcon = 'el-icon-full-screen'
        this.fullscreenTip = '全屏'
        document.webkitExitFullscreen()
      }
    },
    dropdownCommand (command) {
      if (command === 'logout') {
        this.$store.dispatch('init')
        this.$router.push('/login')
      } else if (command === 'changePassword') {
        this.changePasswordDialogVisible = true
      }
    },
    handerMouserwheel (e) {
      const tagBox = document.getElementById('tag_box')
      const tag = document.getElementById('tag')
      const sub = tag.clientWidth - tagBox.clientWidth
      const left = parseInt(this.style.left)
      const data = 50
      if (sub > 0) {
        if (e.wheelDelta > 0) {
          this.style = {
            left: (left - data < -sub ? -sub - 5 : left - data) + 'px'
          }
        } else {
          this.style = {
            left: (left + data > 0 ? 0 : left + data) + 'px'
          }
        }
      }
    },
    tagClick (tag) {
      if (tag.path !== this.$route.path) {
        this.$router.push(tag.path)
      }
    },
    tagClose (tag) {
      const tagList = this.$store.state.tagList.slice(0)
      tagList.splice(tagList.indexOf(tag), 1)
      this.$store.commit('tagList', tagList)
      if (tag.path === this.$route.path) {
        this.$router.push(tagList[tagList.length - 1].path)
      }
    },
    changePasswordDialogClose () {
      this.$refs.changePasswordFormRef.resetFields()
    },
    changePasswordFormSubmit () {
      this.$refs.changePasswordFormRef.validate(async (valid) => {
        if (valid) {
          await changePassword(this.changePasswordForm)
          this.changePasswordDialogVisible = false
          this.$store.dispatch('init')
          this.$router.push('/login')
        }
      })
    }
  }
}
</script>

<style>
.el-menu:not(.el-menu--collapse) {
  width: 200px;
}
.el-menu--collapse .el-submenu .el-icon-arrow-right,
.el-menu--collapse .el-submenu span {
  visibility: hidden;
}
.el-menu .el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}
.el-popper[x-placement^='bottom'] {
  margin-top: -5px !important;
}
.el-tooltip__popper[x-placement^='bottom'] {
  margin-top: -5px !important;
}
.icon_btn {
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}
.icon_btn:hover {
  background-color: #f8f8f9;
}
#collapse_btn {
  float: left;
}
#fullscreen_btn {
  float: right;
}
#username {
  float: right;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 13px;
}
#index .el-dropdown {
  margin-right: 20px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
}
#tag_box {
  height: 36px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}
#tag {
  height: 36px;
  position: absolute;
  display: flex;
}
.el-tag {
  cursor: pointer;
  align-self: center;
  margin-left: 5px;
}
</style>
